import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-newark-new-jersey.png'
import image0 from "../../images/cities/scale-model-of-secaucus-mural-in-newark-new-jersey.png"
import image1 from "../../images/cities/scale-model-of-barrett-triangle-in-newark-new-jersey.png"
import image2 from "../../images/cities/scale-model-of-prudential-outdoor-learning-in-newark-new-jersey.png"
import image3 from "../../images/cities/scale-model-of-the-jewish-museum-of-new-jersey-in-newark-new-jersey.png"
import image4 from "../../images/cities/scale-model-of-staten-island-children's-museum-in-newark-new-jersey.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Newark'
            state='New Jersey'
            image={image}
            lat='40.7242'
            lon='-74.1726'
            attractions={ [{"name": "Secaucus mural", "vicinity": "836 1st St, Secaucus", "types": ["point_of_interest", "establishment"], "lat": 40.7927087, "lng": -74.0584093}, {"name": "Barrett Triangle", "vicinity": "Bay St & Hyatt St, Staten Island", "types": ["park", "point_of_interest", "establishment"], "lat": 40.6418112, "lng": -74.07580949999999}, {"name": "Prudential Outdoor Learning", "vicinity": "32 Prince St, Newark", "types": ["park", "store", "point_of_interest", "establishment"], "lat": 40.7361792, "lng": -74.18509689999996}, {"name": "The Jewish Museum of New Jersey", "vicinity": "145 Broadway, Newark", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.7552684, "lng": -74.16983809999999}, {"name": "Staten Island Children's Museum", "vicinity": "1000 Richmond Terrace Building M Enter via the Fillmore St. Gate off, Tysen St, Staten Island", "types": ["museum", "point_of_interest", "establishment"], "lat": 40.6426449, "lng": -74.10191939999999}] }
            attractionImages={ {"Secaucus mural":image0,"Barrett Triangle":image1,"Prudential Outdoor Learning":image2,"The Jewish Museum of New Jersey":image3,"Staten Island Children's Museum":image4,} }
       />);
  }
}